import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Contact from "../components/Contact"

const ContactUs = () => (
  <Layout>
    <SEO
      title="Contact Thesis | A digital agency"
      description="Contact Thesis by filling out the form on this page. From new-business inquiries to career opportunities to just because you can, we’re here for it."
    />
    <Contact />
  </Layout>
)

export default ContactUs

import React, { Component } from "react"
import "url"
import { Link } from "gatsby"
import InnerForm from "./InnerForm/"
import ErrorMessage from "./ErrorMessage"
import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

import close from "./img/x.svg"
const forms = ["partner", "team", "brain"]
const labels = ["Be our partner", "Join our team", "Pick our brain"]

export default class Contact extends Component {
  state = {
    form: "brain",
    name: "",
    email: "",
    message: "",
    errors: { name: "", email: "", message: "", form: "" },
    complete: false,
    failure: "",
  }
  handleSelect = e => {
    let form = e.target.value
    if (!forms.includes(form)) form = forms[0]
    this.setState({ form: e.target.value })
  }
  handleChange = e => {
    const {
      target: { name, value },
    } = e
    this.setState({ [name]: value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.isValid()) return

    const { name, email, message, form } = this.state
    const body = new URLSearchParams()
    body.append("form-name", form)
    body.append("name", name)
    body.append("email", email)
    body.append("message", message)

    fetch("/contact-us/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => {
        this.setState({ complete: true })
      })
      .catch(err => {
        this.setState({ complete: false, failure: err.message })
      })
  }

  isValid() {
    const { name, email, message, form } = this.state
    let isValid = true
    let errors = { name: "", email: "", message: "", form: "" }
    let emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    if (!form) {
      errors.form = "Please make a selection"
    }
    if (!name) {
      errors.name = "Enter a name"
      isValid = false
    }
    if (!email) {
      errors.email = "Enter an email address"
      isValid = false
    } else if (!emailValid) {
      errors.email = "Enter an email address"
      isValid = false
    }
    if (!message) {
      errors.message = "Enter a message"
      isValid = false
    }
    this.setState({ errors })
    return isValid
  }

  render() {
    const { form, failure, complete, errors } = this.state
    const hideSelect = failure || complete
    return (
      <section className={styles.Contact}>
        <Reveal>
          <div className={styles.inner}>
            <div className={styles.close}>
              <Link to="/">
                <img src={close} />
              </Link>
            </div>
            {!this.state.complete && <h1>Let&apos;s talk.</h1>}
            {!hideSelect && (
              <form>
                <div className={styles.wrap}>
                  <div className={styles.select}>
                    <select
                      className={styles.selected}
                      value={form}
                      onChange={this.handleSelect}
                    >
                      <option value="brain">What brings you here today?</option>
                      {forms.map((form, i) => (
                        <option value={form} key={labels[i]}>
                          {labels[i]}
                        </option>
                      ))}
                    </select>
                    <ErrorMessage error={errors.form} />
                  </div>
                </div>
              </form>
            )}

            <div>
              <form
                name={form}
                className={styles.contactForm}
                method="POST"
                data-netlify="true"
                onSubmit={this.handleSubmit}
              >
                <input type="hidden" name="form-name" value={form} />
                <InnerForm handleChange={this.handleChange} {...this.state} />
              </form>
            </div>
          </div>
        </Reveal>
      </section>
    )
  }
}

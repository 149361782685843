import React, { Component } from "react"

import { noop } from "lodash"
import ErrorMessage from "./../ErrorMessage"

import styles from "./../styles.module.less"
import emdashblack from "./../img/emdash_black.png"

export default class Contact extends Component {
  static defaultProps = {
    complete: false,
    handleChange: noop,
    name: "",
    email: "",
    message: "",
    errors: { name: "", email: "", message: "" },
    failure: "",
  }
  handleInputChange = e => {
    this.props.handleChange(e)
  }
  renderComplete() {
    return (
      <div className={styles.completeMessage}>
        <h1>Success.</h1>
        <p>
          Thanks for reaching out.
          <br />
          Our turn to do our part.
        </p>
      </div>
    )
  }
  renderFailure() {
    return (
      <div className={styles.completeMessage}>
        <h1>Your message could not be delivered</h1>
        <p>
          We apologize for any inconvenience.
          <br />
          Please try again later.
        </p>
      </div>
    )
  }
  renderForm() {
    const { name, email, message, errors } = this.props
    return (
      <section className={styles.InnerForm}>
        <div className={styles.inner}>
          <div>
            <h2>First & Last Name</h2>
            <div className={styles.wrap}>
              <div className={styles.input}>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange}
                  value={name}
                />
                <span className={styles.borda}></span>
              </div>
            </div>
            <ErrorMessage error={errors.name} />
          </div>
          <div>
            <h2>Email</h2>
            <div className={styles.wrap}>
              <div className={styles.input}>
                <input
                  type="email"
                  name="email"
                  onChange={this.handleInputChange}
                  value={email}
                />
                <span className={styles.borda}></span>
              </div>
            </div>
            <ErrorMessage error={errors.email} />
          </div>
          <div>
            <h2>Comments</h2>
            <div className={styles.wrap}>
              <div className={styles.input}>
                <textarea
                  name="message"
                  type="text"
                  onChange={this.handleInputChange}
                  value={message}
                />
                <span className={styles.borda}></span>
              </div>
            </div>
            <ErrorMessage error={errors.message} />
          </div>

          <nav className={styles.links}>
            <button
              className={styles.cta}
              label="Submit"
              type="submit"
              onClick={this.handleValidation}
            >
              <span>Send it</span>
              <img src={emdashblack} alt="" />
            </button>
          </nav>
        </div>
      </section>
    )
  }
  render() {
    const { complete, failure } = this.props
    if (failure) return this.renderFailure()
    return complete ? this.renderComplete() : this.renderForm()
  }
}
